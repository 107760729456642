<template>
  <div class="p-1">
    <c-flex p="10px" w="100%">
      <c-box w="100%">
        <c-tabs v-if="hasFollowers || hasFollowing" variant-color="vc-orange">
          <c-tab-list>
            <c-tab><small>Followers</small></c-tab>
            <c-tab><small>Following</small> </c-tab>
          </c-tab-list>

          <c-tab-panels w="100%">
            <c-tab-panel>
              <div class="p-4" style="background-color: #dfe9f866">
                <c-spinner
                  ml="2"
                  color="orange"
                  thickness="2px"
                  v-if="dataLoading"
                />
                <div v-else>
                  <c-flex
                    flexWrap="wrap"
                    justify="start"
                    align="center"
                    v-if="hasFollowers"
                  >
                    <follower-card
                      v-for="(foll, index) in userFollowers"
                      :key="index"
                      :user="foll"
                      type="followers"
                    />
                  </c-flex>
                  <p v-else>Has no followers</p>
                  <infinite-loading @infinite="getMoreFollowers">
                    <span slot="no-more">
                      <div style="color: rgba(255, 69, 0, 0.45)">No more followers</div>
                    </span>
                            <span slot="no-results">
                      <div style="color: rgba(255, 69, 0, 0.45)">No followers yet</div>
                    </span>
                  </infinite-loading>
                </div>
              </div>
            </c-tab-panel>
            <c-tab-panel>
              <div class="p-4" style="background-color: #dfe9f866">
                <c-spinner
                  ml="2"
                  color="orange"
                  thickness="2px"
                  v-if="dataLoading"
                />

                <div v-else>
                  <c-flex
                    flexWrap="wrap"
                    justify="start"
                    align="center"
                    v-if="hasFollowing"
                  >
                    <follower-card
                      v-for="(foll, index) in userFollowing"
                      :key="index"
                      :user="foll"
                    />
                  </c-flex>
                  <p v-else>Has no following connections</p>
                  <infinite-loading @infinite="getMoreFollowings">
                    <span slot="no-more">
                      <div style="color: rgba(255, 69, 0, 0.45)">No more followings</div>
                    </span>
                    <span slot="no-results">
                      <div style="color: rgba(255, 69, 0, 0.45)">No followings yet</div>
                    </span>
                  </infinite-loading>
                </div>
              </div>
            </c-tab-panel>
          </c-tab-panels>
        </c-tabs>
        <div v-else>No Connections yet</div>
      </c-box>
    </c-flex>
  </div>
</template>

<script>
import { getUsersFollowers, getUserFollowing } from '@/services/user';
import FollowerCard from '@/views/App/Profile/components/FollowerCard.vue';
import {mapGetters} from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import cloneDeep from "lodash.clonedeep";

export default {
  name: 'NetworkPage',
  components: {
    FollowerCard,
    InfiniteLoading
  },

  data() {
    return {
      dataLoading: true,
      userFollowers: [],
      userFollowing: [],
      followersTotal: 0,
      followersOffset: 0,
      followersLimit: 6,
      followingLimit: 6,
      followingOffset: 0,
      followingTotal: 0
    };
  },
  props: ['isFollowedUser'],
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    currentUserFollowing(){
      return this.user.user_followers
    },
    userId() {
      return this.$route.params?.userId;
    },
    hasFollowers() {
      return this.userFollowers.length > 0;
    },
    hasFollowing() {
      return this.userFollowing.length > 0;
    },
  },
  watch: {
    isFollowedUser(newVal){
      console.log('Crah==>',newVal);
      console.log(this.userFollowers)
      console.log(this.user)
      if(newVal === true){
        this.userFollowers.push({
          follower: this.user,
        });
      } else if (newVal === false) {
        this.userFollowers = this.userFollowers.filter(
          (flw) => flw.follower.id !== this.user.id
        );
      }
    },
  },
  created() {
    this.getConnectionsData();
    console.log('Follox==>', this.currentUserFollowing)
  },
  methods: {
    getMoreFollowers($state){
      this.followersOffset = this.followersOffset + this.followersLimit;
      getUsersFollowers({userId: this.userId, limit: this.followersLimit, offset: this.followersOffset})
          .then((res) => {
            this.userFollowers = [...cloneDeep(this.userFollowers), ...res.data.user_follower]
            $state.loaded();
            if (this.followersTotal === this.userFollowers.length) {
              $state.complete();
            }
          })
    },

    getMoreFollowings($state){
      this.followingOffset = this.followingOffset + this.followingLimit;
      getUserFollowing({userId: this.userId, limit: this.followingLimit, offset: this.followingOffset})
          .then((res) => {
            this.userFollowing = [...cloneDeep(this.userFollowing), ...res.data.user_follower]
            $state.loaded();
            if (this.followingTotal === this.userFollowing.length) {
              $state.complete();
            }
          })
    },
    async getConnectionsData() {
      try {
        const followers = await getUsersFollowers({userId: this.userId, limit: this.followersLimit, offset: this.followersOffset} );
        const following = await getUserFollowing({userId: this.userId, limit: this.followingLimit, offset: this.followingOffset});

        this.userFollowers = followers.data.user_follower;
        this.followersTotal = followers.data.user_follower_aggregate.aggregate.count
        this.userFollowing = following.data.user_follower;
        this.followingTotal = following.data.user_follower_aggregate.aggregate.count
        console.log('dfdfdf', this.userFollowers)

        this.$emit(
          'getFollowingData',
          this.userFollowers.length,
          this.userFollowing.length
        );
        this.dataLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
