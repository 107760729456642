import { render, staticRenderFns } from "./NetworkTab.vue?vue&type=template&id=ae97af18"
import script from "./NetworkTab.vue?vue&type=script&lang=js"
export * from "./NetworkTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CSpinner: require('@chakra-ui/vue').CSpinner, CFlex: require('@chakra-ui/vue').CFlex, CTabPanel: require('@chakra-ui/vue').CTabPanel, CTabPanels: require('@chakra-ui/vue').CTabPanels, CTabs: require('@chakra-ui/vue').CTabs, CBox: require('@chakra-ui/vue').CBox})
